import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueCryptojs from 'vue-cryptojs'

import VueGtag from "vue-gtag";
import Clarity from '@microsoft/clarity';

import './styles/app.css'

const options = {
  confirmButtonColor: '#51d86d',
}

createApp(App)
.use(router)
.use(VueSweetalert2, options)
.use(VueCryptojs)
.use(VueGtag, {
  config: { id: "G-TYG1XT18CH" }
})
.mount('#app')

const projectId = "ogg0irj1mq"

Clarity.init(projectId);
